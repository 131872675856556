import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Attendance = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        "https://meetup-api.betca.org/check-user",
        {
          phoneNumber,
        }
      );

      if (response.status === 200 || response.status === 201) {
        const responseData = response.data;
        if (responseData.message.includes("Welcome back")) {
          toast.success(responseData.message);
        } else {
          toast.info(responseData.message);
        }
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        toast.info(
          "Sorry, your entry is not present. Please walk to the registration counter and register."
        );
      } else {
        console.error(error);
        toast.error("An error occurred while processing your request.");
      }
    }
  };

  return (
    <div className="relative h-[86dvh]">
      <div className="bg-green-50 bg-opacity-50 py-3 flex flex-col justify-center sm:py-6 mx-4 sm:mx-20">
        <div className="text-center text-black font-semibold text-lg sm:text-2xl py-2 animate-slide absolute top-4 left-0 right-0">
          Belagavi Technology Companies Association (BeTCA)
        </div>

        <div className="relative mt-28 sm:max-w-xl sm:mx-auto">
          <div className="absolute inset-0 bg-gradient-to-r from-green-200 to-green-400 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
          <div className="relative px-4 py-6 bg-white shadow-lg sm:rounded-3xl sm:p-8">
            <div className="max-w-md mx-auto">
              <h1 className="text-2xl font-semibold text-center">
                Mark Your Attendance
              </h1>
              <div className="divide-y divide-gray-200">
                <div className="py-4 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <span className="text-xs text-light text-center">
                    Enter your Registered Phone Number
                  </span>

                  <input
                    autoComplete="off"
                    id="number"
                    name="number"
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => {
                      // Use a regular expression to remove non-digit characters
                      const sanitizedValue = e.target.value.replace(/\D/g, "");

                      // Limit the value to a maximum length of 10 digits
                      const maxLength = 10;
                      const truncatedValue = sanitizedValue.slice(0, maxLength);

                      // Update the state with the truncated value
                      setPhoneNumber(truncatedValue);
                    }}
                    className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-green-600"
                    placeholder="Phone Number"
                    required
                  />

                  <div className="relative text-center">
                    <button
                      onClick={handleSubmit}
                      className="bg-green-500 text-white rounded-md px-4 py-2"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attendance;
