import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    // amount: "100",
    companyName: "",
    workCity: "",
    designation: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://meetup-api.betca.org/register",
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.error || response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while processing your request.");
    }
  };

  return (
    <>
      <div
        className="h-[100dvh] flex items-center justify-center bg-center px-4 sm:px-6 lg:px-8 bg-gray-500 bg-no-repeat bg-cover pt-12 pb-32 lg:pt-0 lg:pb-0"
        style={{
          backgroundImage:
            "url(https://images.unsplash.com/photo-1532423622396-10a3f979251a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1500&q=80)",
        }}
      >
        <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
        <div className="max-w-md p-6 bg-white rounded-xl shadow-lg z-10 lg:mb-0 lg:-mt-6 ">
          <div className="grid gap-8 grid-cols-1">
            <div className="flex flex-col">
              <div className="flex flex-col sm:flex-row items-center">
                <div className="font-semibold text-sm sm:text-lg  mr-auto">
                  Submit this form to express your interest in being a member of
                  BeTCA : Belagavi Technology Companies Association
                </div>
              </div>
              <div className="mt-5">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3 space-y-2 w-full text-xs">
                    <label className="font-semibold text-gray-600 py-2">
                      Name <abbr title="required">*</abbr>
                    </label>
                    <input
                      placeholder="Name"
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                      type="text"
                      name="name"
                      id="name"
                      required
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 space-y-2 w-full text-xs">
                    <label className="font-semibold text-gray-600 py-2">
                      Email <abbr title="required">*</abbr>
                    </label>
                    <input
                      placeholder="Email"
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                      type="email"
                      name="email"
                      id="email"
                      required
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 space-y-2 w-full text-xs">
                    <label className="font-semibold text-gray-600 py-2">
                      Contact Number <abbr title="required">*</abbr>
                    </label>
                    <input
                      placeholder="Phone Number"
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      required
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                  </div>
                  {/* <div className="mb-3 space-y-2 w-full text-xs">
                    <label className="font-semibold text-gray-600 py-2">
                      Amount
                    </label>
                    <input
                      placeholder="Amount"
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                      type="text"
                      name="amount"
                      id="amount"
                      value={formData.amount}
                      onChange={handleChange}
                    />
                  </div> */}
                  {/* 
                  <div className="mb-3 space-y-2 w-full text-xs">
                    <label className="font-semibold text-gray-600 py-2 px-5">
                      Payment Method
                    </label>
                    <label className="font-semibold text-gray-600 py-2 px-5">
                      <input
                        className=" px-4"
                        type="radio"
                        name="paymentMethod"
                        value="Cash"
                        onChange={handleChange}
                        // checked={formData.paymentMethod === "Cash"}
                      />
                      Cash
                    </label>
                  </div> */}

                  <div className="mb-3 space-y-2 w-full text-xs">
                    <label className="font-semibold text-gray-600 py-2">
                      Company Name <abbr title="required">*</abbr>
                    </label>
                    <input
                      placeholder="Company Name"
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                      type="text"
                      name="companyName"
                      id="companyName"
                      required
                      value={formData.companyName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 space-y-2 w-full text-xs">
                    <label className="font-semibold text-gray-600 py-2">
                      Designation <abbr title="required">*</abbr>
                    </label>
                    <input
                      placeholder="Designation"
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                      type="text"
                      name="designation"
                      id="designation"
                      required
                      value={formData.designation}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 space-y-2 w-full text-xs">
                    <label className="font-semibold text-gray-600 py-2">
                      Work City <abbr title="required">*</abbr>
                    </label>
                    <input
                      placeholder="Work City"
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                      type="text"
                      name="workCity"
                      id="workCity"
                      required
                      value={formData.workCity}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="flex justify-center">
                    <button className="bg-green-500 text-white font-medium py-2 px-6 rounded-full hover:bg-green-600 hover:shadow-lg">
                      Express your Interest
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
