import React from "react";
import Error404Img from "../assets/images/404.svg";

function Error404() {
  return (
    <div className="h-[84dvh] flex flex-col gap-8 items-center flex-wrap">
      <img src={Error404Img} alt="404 page" width={350} />
      <p className="font-sans text-2xl font-bold">Page Not Found</p>
    </div>
  );
}

export default Error404;
