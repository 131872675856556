import React from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Header = () => {
  const location = useLocation();

  const showRegisterMessage = () => {
    toast.success("Please walk to the Registration counter to Register!", {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: true,
    });
  };

  const handleRegisterClick = () => {
    showRegisterMessage();
  };

  const isRegisterPage = location.pathname === "/register";
  return (
    <header className="sticky top-0 z-50 bg-white">
      <div className="bg-green-400 w-100 h-4"></div>
      <div className="text-gray-600 body-font border-2 border-b shadow-light transition-colors duration-300">
        <div className="container mx-auto flex p-3 flex-wrap md: items-center ">
          <div
            className={`flex lg:order-none lg:${
              isRegisterPage ? "mx-auto" : "ml-auto"
            } lg: items-center lg:items-center lg:justify-center md:mb-0`}
          >
            <Link to="/">
              <img src="/betca_logo_poster.png" alt="Logo" width={110} />
            </Link>
          </div>

          {!isRegisterPage && (
            <button
              onClick={handleRegisterClick}
              className="bg-green-500 hover:bg-indigo-600 text-white font-medium py-2 px-6 rounded-full mt-4 md:mt-0 ml-auto transform transition-transform duration-300 hover:scale-105"
            >
              Register
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
